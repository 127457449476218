import { HttpResponse } from "../models/httpResponse";
import { RegisterResponseData } from "../models/registerResponseData";
import { http } from "./http";
import { ResetPasswordRequestBody, RegisterRequestBody, LoginRequestBody, LogoutRequestBody, ForgotPasswordRequestBody } from "../models/auth.model";
import { Account } from "../models/account";

class AuthApi {
    async hello(body: any): Promise<HttpResponse<RegisterResponseData>> {
        let response = await http.post('https://jsonplaceholder.typicode.com/users', body, null);
        return response;
    }

    async resetPassword(body: ResetPasswordRequestBody): Promise<HttpResponse<RegisterResponseData>> {
        let response = await http.post('auth/account/forgotPassword', body, null);
        return response;
    }

    async register(body: RegisterRequestBody): Promise<HttpResponse<RegisterResponseData>> {
        let response = await http.post('auth/account/register', body, null);
        return response;
    }

    async login(body: LoginRequestBody): Promise<HttpResponse<RegisterResponseData>> {
        let response = await http.post('auth/account/login', body, null);
        return response;

    }

    async logout(body: LogoutRequestBody): Promise<HttpResponse<RegisterResponseData>> {
        const header = await http.getAuthorizedHeader();
        let response = await http.post('auth/account/logout', body, header);
        return response;
    }

    async getAccountInfo(): Promise<HttpResponse<Account>> {
        const header = await http.getAuthorizedHeader();
        let response = await http.get('auth/account/info', header);
        return response;
    }
    async forgotPassword(data: ForgotPasswordRequestBody): Promise<HttpResponse<Account>> {
        const header = await http.getAuthorizedHeader();
        let response = await http.post('auth/account/forgotPassword', data, header);
        return response;
    }

    async newPassword(data: any): Promise<HttpResponse<RegisterResponseData>> {
        const header = await http.getAuthorizedHeader();
        let response = await http.put('auth/account/register/merchant/verify', data, header);
        return response;
    }

    async changeNotificationSetting(data: any): Promise<HttpResponse<any>> {
        const header = await http.getAuthorizedHeader();
        let response = await http.put('notification/setting', data, header);
        return response;
    }

    async getNotificationSetting(): Promise<HttpResponse<any>> {
        const header = await http.getAuthorizedHeader();
        let response = await http.get('notification/setting', header);
        return response;
    }

    async changePassword(data: any): Promise<HttpResponse<any>> {
        const header = await http.getAuthorizedHeader();
        let response = await http.post('auth/account/changePassword', data, header);
        return response;
    }
}
export const authApi = new AuthApi();