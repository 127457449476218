import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import './newPassword.scss';
import logo from '../../../assets/imgs/logo.png';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { appConfig } from '../../../appConfig';
import { NewPasswordStoreClass, NewPasswordStore } from './newPasswordStore';
import { MIN_PASSWORD_LENGTH } from '../../../constant';
import { FooterComponent } from '../../../components/footer/footerComponent';

export let newPasswordStore: NewPasswordStoreClass;

class Wrapper extends React.Component {

  componentDidMount() {
    newPasswordStore?.init();
  }

  render() {
    return this.props.children;
  }
}

const Render = () => {

  const { t } = useTranslation();
  newPasswordStore = useContext(NewPasswordStore);
  newPasswordStore.query = new URLSearchParams(useLocation().search);

  return (
    <div className='login-container'>
      <div className='card w-100'>
        <div className='card-body'>
          <form onSubmit={newPasswordStore.onSubmitLogin}>
            <div className='text-center'>
              <img width='80px' height='80px' src={logo} />
            </div>
            <h1 className='text-center mb-3'>{t('create_password')}</h1>

            <div className='d-flex align-items-center mt-2 mb-1'>
              <span className='text-muted mb-0 mr-1'>{t('brand_code')}:</span>
              <h6 className='text-primary mb-0'>{newPasswordStore.brandCode}</h6>
            </div>

            <div className='d-flex align-items-center mt-0 mb-2'>
              <span className='text-muted mb-0 mr-1'>{t('email')}:</span>
              <h6 className='text-primary mb-0'>{newPasswordStore.username}</h6>
            </div>

            <div className='mb-2'>
              <label className='input-label'>{t('password')}</label>
              <div className='input-group'>
                <div className='input-group-prepend'   >
                  <span className="input-group-text"><i className="fa fa-lock"></i></span>
                </div>
                <input type='password' name='password' minLength={MIN_PASSWORD_LENGTH} placeholder={t('password')} className='form-control'
                  value={newPasswordStore.password}
                  onChange={(e: any) => newPasswordStore.passwordChange(e.target.value)} />
              </div>
              {
                !newPasswordStore.password && <span className='text-error' >
                  {t('required')}
                </span>
              }
              {
                (newPasswordStore.password.length > 0
                  && newPasswordStore.password.length < MIN_PASSWORD_LENGTH) && <span className='text-error' >
                  {t('password_must_be_at_least_character')}
                </span>
              }
            </div>

            <div className='mb-2'>
              <label className='input-label'>{t('retype_password')}</label>
              <div className='input-group'>
                <div className='input-group-prepend'  >
                  <span className="input-group-text"><i className="fa fa-lock"></i></span>
                </div>
                <input type='password' name='retypePassword' minLength={MIN_PASSWORD_LENGTH} placeholder={t('retype_password')} className='form-control'
                  value={newPasswordStore.retypePassword}
                  onChange={(e: any) => newPasswordStore.retypePasswordChange(e.target.value)}
                />
              </div>
              {
                !newPasswordStore.retypePassword && <span className='text-error' >
                  {t('required')}
                </span>
              }
              {
                newPasswordStore.password && newPasswordStore.retypePassword
                && newPasswordStore.retypePassword != newPasswordStore.password
                &&
                <span className='text-error' >
                  {t('retype_password_not_match')}
                </span>
              }
            </div>
            <div className='row'>
              <div className='col-12 text-center'>
                <button type='submit' disabled={
                  !newPasswordStore.password?.trim()
                  || !newPasswordStore.retypePassword.trim()
                  || newPasswordStore.retypePassword != newPasswordStore.password
                  || newPasswordStore.password?.length < MIN_PASSWORD_LENGTH
                }
                  className='btn btn-primary px-4'>{t('confirm')}</button>
              </div>
            </div>

            <div className='text-left'>
              <button type='button' onClick={newPasswordStore.onClickGoBackToLogin} className='btn btn-link px-0'>
                &lt;{t('back_to_login')}
              </button>
            </div >

            {
              newPasswordStore.isSent &&
              <div className="alert alert-success">
                {t('request_forgot_password_sent')}
              </div>
            }

            <FooterComponent />
          </form>
        </div >
      </div >


    </div >

  );
};

const ObserverLogin = observer(Render);
export const NewPassword = () => { return (<Wrapper> <ObserverLogin /> </Wrapper>) };
