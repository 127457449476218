
import { observable } from 'mobx';
const appVersion = '0.0.3';

export const config = {
    development: {
        name: 'development',
        appVersion: appVersion,
        apiDomain: 'https://development.loyaworld.com:4001',
        // loyaOneBizLink: 'http://localhost:4600/#/auth/login',
        // loyaWorldBizLink: 'http://localhost:4300/#/auth/login',
        loyaOneBizLink: 'https://loyaonepartnerdev.firebaseapp.com/#/auth/login',
        loyaWorldBizLink: 'https://loyawebshop.firebaseapp.com/#/auth/login',
    },
    staging: {
        name: 'staging',
        appVersion: appVersion,
        apiDomain: 'https://staging.loyaworld.com:4001',
        loyaOneBizLink: 'https://staging.loyaworld.com:89/#/auth/login',
        loyaWorldBizLink: 'https://staging.loyaworld.com:81/#/auth/login',
    },
    production: {
        name: 'production',
        appVersion: appVersion,
        apiDomain: 'https://api.loya.one:4001',
        loyaOneBizLink: 'https://biz.loya.one/#/auth/login',
        loyaWorldBizLink: 'https://biz.loyaworld.com/#/auth/login',
    }
}

class AppConfig {

    @observable
    config = config.production;
    constructor() {
    }

}

export const appConfig = new AppConfig();

