
export const LOYA_NAME = 'Loya One';

export enum LOYA_SERVICE {
    LOYA_ONE = 'loya_one',
    LOYA_WORLD = 'loya_world',
}
export enum LOYA_URL_KEY {
    SERVICE = 'service',
    AT = 'at',
    SCREEN_URI = 'screen_uri',
}

export const URI = {
    APPLICATION: '/application',
    FORGOT_PASWORD: '/auth/forgot-password',
    NEW_PASWORD: '/auth/new-password',
    LOGIN: '/auth/login',
    ACCOUNT_INFO: '/account-info',
    LAYOUT: '/',
};

export const LOGIN_TYPE = {
    MERCHANT: 'MERCHANT',
};
export const STATUS_CODE = {
    SUCCESS: 991,
    CREATED: 992,
    NOT_FOUND: 994,
    USER_INVALID: 901,
    ERROR_UNDEFINED: 900,
    WRONG_BRAND_CODE: 903,
    PASSWORD_INVALID: 904,
    ID_INVALID: 906,
    USER_IS_EXISTED: 907,
    DATA_IS_EXIST: 950,
    INVALID_STATUS: 954,
    USER_INACTIVE: 908,
    DATA_IS_INVALID: 951,
    NOT_POSITIVE_NUMBER: 953,
};
export const SOCIAL_METHOD = {
    PHONE: 'phone',
    GOOGLE: 'google',
    FACEBOOK: 'facebook',
};

export const EVENT_EMITER = {
    TOASTR: 'TOASTR',
    LOADING_ALL: 'LOADING_ALL',
}

export const MIN_PASSWORD_LENGTH = 6;