import { observable, action, reaction } from "mobx";
import { createContext } from "react";
import { config, appConfig } from "../../../appConfig";
import { logger } from "../../../helpers/logger";
import { STATUS_CODE, LOGIN_TYPE, URI, LOYA_SERVICE, LOYA_URL_KEY, EVENT_EMITER } from "../../../constant";
import { authApi } from "../../../api/auth.api";
import { LoginRequestBody } from "../../../models/auth.model";
import { alertHelper } from "../../../helpers/toastHelper";
import { helper } from "../../../helpers/helper";
import { localAppStorageHelper } from "../../../helpers/localAppStorageHelper";
import { Account } from "../../../models/account";
import { globalEvent } from "../../../helpers/globalEvents";
import { authHelper } from "../../../helpers/authHelper";
import { stores } from "../../../store";
const md5 = require('md5');

export class LoginStoreClass {

  @observable brandCode = '';
  @observable username = '';
  @observable password = '';
  @observable screenUri = '';
  @observable service = '';
  @observable query = new URLSearchParams();

  constructor() {

  }

  init = () => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      logger.info('ON DEV LOCAL');
      if (appConfig.config.name === config.development.name) {
        // this.brandCode = 'pnj';
        // this.username = 'loya.intern1@gmail.com';
        // this.password = '123456hh';
        // this.brandCode = 'hangpharma';
        // this.username = 'hangjeny08@gmail.com';
        // this.password = '123456';
        // this.brandCode = 'hangpharma';
        // this.username = 'loya.intern1@gmail.com';
        // this.password = '123456';
        // this.brandCode = 'ht';
        // this.username = 'hoangdanh010190@gmail.com';
        // this.password = '123456';
        this.brandCode = 'ht';
        this.username = 'hangjeny08@gmail.com';
        this.password = '123456hh';
        // this.brandCode = 'hm';
        // this.username = 'loya.intern1@gmail.com';
        // this.password = '123456';
      } else if (appConfig.config.name === config.staging.name) {
        this.brandCode = 'HT';
        this.username = 'hangjeny08@gmail.com';
        this.password = '123456hh';
        // this.brandCode = 'HJ';
        // this.username = 'loya.intern1@gmail.com';
        // this.password = '123456hh';
      } else if (appConfig.config.name === config.production.name) {
        this.brandCode = '03';
        this.username = 'linh.tran@loyaworld.com';
        this.password = '123456';
      }
    }

    if (this.query.get('brandCode')) {
      this.brandCode = this.query.get('brandCode') || '';
    }
    if (this.query.get('username')) {
      this.username = this.query.get('username') || '';
    }
    if (this.query.get(LOYA_URL_KEY.SERVICE)) {
      this.service = this.query.get(LOYA_URL_KEY.SERVICE) || '';
    }
    if (this.query.get(LOYA_URL_KEY.SCREEN_URI)) {
      this.screenUri = this.query.get(LOYA_URL_KEY.SCREEN_URI) || '';
    }

    authHelper.validateLoggedInData(this.query, true);
  }

  brandCodeChange = (text: string) => {
    this.brandCode = text;
  }

  passwordChange = (text: string) => {
    this.password = text;
  }

  usernameChange = (text: string) => {
    this.username = text;
  }

  onSubmitLogin = async (event: any) => {
    event.preventDefault();
    try {
      globalEvent.loadingAll.emit(EVENT_EMITER.LOADING_ALL, true);
      // const brandCode = helper.getUrlParams('brandCode');
      // const username = helper.getUrlParams('username');
      const model: LoginRequestBody = {
        code: this.brandCode?.trim(),
        username: this.username?.trim(),
        password: md5(this.password),
        socialMethod: 'phone',
        type: LOGIN_TYPE.MERCHANT,

      };
      const res = await authApi.login(model);

      if (res.status_code === STATUS_CODE.SUCCESS) {
        authHelper.processLogin({
          account: res.data.account,
          at: res.data.authorization,
        }, this.query);
      } else if (res.status_code === STATUS_CODE.INVALID_STATUS) {
        alertHelper.warning('brand_was_locked_please_contact');
      } else if (res.status_code === STATUS_CODE.NOT_FOUND
        || res.status_code === STATUS_CODE.PASSWORD_INVALID
        || res.status_code === STATUS_CODE.ERROR_UNDEFINED) {
        alertHelper.warning('wrong_login_data');
      } else if (res.status_code === STATUS_CODE.USER_INACTIVE) {
        alertHelper.warning('account_has_been_locked');
      } else {
        throw res;
      }
    } catch (err) {
      helper.processCommonError(err);
    } finally {
      globalEvent.loadingAll.emit(EVENT_EMITER.LOADING_ALL, false);
    }
  }

  onClickForgotPassword = () => {
    stores.history.push({
      search: `?brandCode=${this.brandCode}&username=${this.username}&${LOYA_URL_KEY.SERVICE}=${this.service}&${LOYA_URL_KEY.SCREEN_URI}=${this.screenUri}`,
      pathname: URI.FORGOT_PASWORD,
    });
  }
}

export const LoginStore = createContext(new LoginStoreClass());
