import { observable } from "mobx";
import { createContext } from "react";
import { Account } from "../../../models/account";
import { localAppStorageHelper } from "../../../helpers/localAppStorageHelper";
import { URI, STATUS_CODE, LOYA_URL_KEY } from "../../../constant";
import { stores } from "../../../store";
import { authApi } from "../../../api/auth.api";
import { helper } from "../../../helpers/helper";
const md5 = require('md5');
import { alertHelper } from "../../../helpers/toastHelper";
import { NotificationForUser } from "../../../models/notificationForUser.model";

export class AccountInfoStoreClass {

  @observable account: Account = {} as any;
  @observable serviceChecked: boolean = false;
  @observable orderChecked: boolean = false;
  @observable warehouseChecked: boolean = false;
  @observable isOpenModal: boolean = false;
  @observable newPassword = '';
  @observable oldPassword = '';
  @observable retypePassword = '';
  @observable query = new URLSearchParams();
  @observable brandCode = '';
  @observable username = '';
  @observable notificationForUser: NotificationForUser = {} as any;
  @observable service = '';
  @observable screenUri = '';

  constructor() {

  }

  init = async () => {
    if (this.query.get(LOYA_URL_KEY.SERVICE)) {
      this.service = this.query.get(LOYA_URL_KEY.SERVICE) || 'loya_one';
    }
    if (this.query.get(LOYA_URL_KEY.SCREEN_URI)) {
      this.screenUri = this.query.get(LOYA_URL_KEY.SCREEN_URI) || '';
    }
    this.account = await localAppStorageHelper.getAccount();
    this.getNotificationSetting();
    console.log(this.service);
  }

  onClickGoBackToAuth = () => {
    stores.history.push({
      search: '',
      pathname: URI.APPLICATION,
    });
  }

  getNotificationSetting = async () => {
    try {
      const res = await authApi.getNotificationSetting();

      if (res.status_code === STATUS_CODE.SUCCESS) {
        this.notificationForUser = res.data;
        this.serviceChecked = this.notificationForUser.enabled;
        this.orderChecked = this.notificationForUser.notifyFromOrder;
        this.warehouseChecked = this.notificationForUser.notifyFromWarehouse;
      } else {
        throw res;
      }
    } catch (err) {
      helper.processCommonError(err);
    }
  }

  handleChangeService = async () => {
    this.serviceChecked = !this.serviceChecked;
    try {
      const res = await authApi.changeNotificationSetting(
        {
          enabled: this.serviceChecked,
          notifyFromOrder: this.orderChecked,
          notifyFromWarehouse: this.warehouseChecked,
        }
      );

      if (res.status_code === STATUS_CODE.SUCCESS) {
        alertHelper.info('edited_success');
      } else {
        throw res;
      }
    } catch (err) {
      helper.processCommonError(err);
    }
  }
  handleChangeOrder = async () => {
    this.orderChecked = !this.orderChecked;
    try {
      const res = await authApi.changeNotificationSetting(
        {
          enabled: this.serviceChecked,
          notifyFromOrder: this.orderChecked,
          notifyFromWarehouse: this.warehouseChecked,
        }
        );

      if (res.status_code === STATUS_CODE.SUCCESS) {
        alertHelper.info('edited_success');
      } else {
        throw res;
      }
    } catch (err) {
      helper.processCommonError(err);
    }
  }
  handleChangeWarehouse = async () => {
    this.warehouseChecked = !this.warehouseChecked;
    try {
      const res = await authApi.changeNotificationSetting(
        {
          enabled: this.serviceChecked,
          notifyFromOrder: this.orderChecked,
          notifyFromWarehouse: this.warehouseChecked,
        }
        );

      if (res.status_code === STATUS_CODE.SUCCESS) {
        alertHelper.info('edited_success');
      } else {
        throw res;
      }
    } catch (err) {
      helper.processCommonError(err);
    }
  }

  closeModal = () => {
    this.isOpenModal = false;
  }
  onClickChangePassword = () => {
    this.isOpenModal = true;
  }

  oldPasswordChange = (text: string) => {
    this.oldPassword = text;
  }

  newPasswordChange = (text: string) => {
    this.newPassword = text;
  }

  retypePasswordChange = (text: string) => {
    this.retypePassword = text;
  }

  onSubmitChangePassword = async (event: any) => {
    event.preventDefault();
    try {
      const model = {
        socialMethod: 'phone',
        password: md5(this.newPassword?.trim()),
        oldPassword: md5(this.oldPassword?.trim()),
      };
      const res = await authApi.changePassword(model);
      if (res.status_code === STATUS_CODE.SUCCESS) {
        alertHelper.success('successfully_change_password');
        this.isOpenModal = false;
        console.log(this.service);
        stores.history.push({
          search: `?brandCode=&username=${this.username}&${LOYA_URL_KEY.SERVICE}=${this.service}&${LOYA_URL_KEY.SCREEN_URI}=${this.screenUri}`,
          pathname: URI.LOGIN,
        });
      } else if (res.status_code === STATUS_CODE.PASSWORD_INVALID) {
        alertHelper.warning('wrong_old_password');
      } else {
        throw res;
      }
    } catch (err) {
      helper.processCommonError(err);
    }

  }

}

export const AccountInfoStore = createContext(new AccountInfoStoreClass());
