import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import './layout.scss';
import { LOYA_NAME, URI } from '../../constant';
import loyaOneLogo from '../../assets/imgs/lo-logo.png';
import loyaWorldLogo from '../../assets/imgs/lw-logo.png';
import { useTranslation } from 'react-i18next';
import { LayoutStore, LayoutStoreClass } from './layoutStore';
import { useHistory, useLocation, Switch, Route } from 'react-router-dom';
import { appConfig } from '../../appConfig';
import { FooterComponent } from '../../components/footer/footerComponent';
import { AccountInfo } from './account/accountInfo';
import { Application } from './application/application';
// import { LayoutStore } from './layoutStore';

let layoutStore: LayoutStoreClass;

class Wrapper extends React.Component {

  componentDidMount() {
    layoutStore?.init();
  }

  render() {
    return this.props.children;
  }
}

const RenderLayout = () => {

  const { t } = useTranslation();
  layoutStore = useContext(LayoutStore);
  layoutStore.history = useHistory();
  layoutStore.location = useLocation();
  layoutStore.query = new URLSearchParams(useLocation().search);

  return (
    <div className='layout-container'>
      <Switch>
        <Route path={URI.ACCOUNT_INFO}>
          <AccountInfo />
        </Route>
        <Route path={URI.APPLICATION}>
          <Application />
        </Route>
      </Switch>
      <FooterComponent />
    </div >

  );
};

const ObserverLayout = observer(RenderLayout);
export const Layout = () => { return (<Wrapper> <ObserverLayout /> </Wrapper >) };
