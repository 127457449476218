import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import './application.scss';
import loyaOneLogo from '../../../assets/imgs/lo-logo.png';
import loyaWorldLogo from '../../../assets/imgs/lw-logo.png';
import { useTranslation } from 'react-i18next';
import { ApplicationStore, ApplicationStoreClass } from './applicationStore';
import { useHistory } from 'react-router-dom';
// import { ApplicationStore } from './applicationStore';

let applicationStore: ApplicationStoreClass;

class Wrapper extends React.Component {

  componentDidMount() {
    applicationStore?.init();
  }

  render() {
    return this.props.children;
  }
}

const RenderApplication = () => {

  const { t } = useTranslation();
  applicationStore = useContext(ApplicationStore);
  applicationStore.history = useHistory();
  // applicationStore.query = new URLSearchParams(useLocation().search);

  return (
    <div className='login-container'>
      <div className='card w-100'>
        <div className='d-flex align-items-center justify-content-between p-2'>
          <span className='text-click d-flex align-items-center'>
            {/* <i className="fa fa-chevron-left pr-1"></i>{t('back_to_login')} */}
          </span>
          <div className=''>
            <small className='text-secondary'>{t('hi')}: </small>
            <span className='font-weight-bold account-info text-click' onClick={applicationStore.onClickAccountInfo}>{applicationStore.account?.name}</span>
            {/* <span className='text-click' onClick={applicationStore.onClickSignOut}>
              <i className="fa fa-sign-out pl-2"></i>
              {t('sign_out')}
            </span> */}
          </div>
        </div>
        <div className='card-body pb-4 pt-3'>

          <h5 className='text-center'>{t('select_service_for_use')}</h5>

          <div className='d-flex align-items-center justify-content-center flex-sm-row flex-column'>
            <div className='app-btn' onClick={applicationStore.onClickLoyaOne}>
              <div className='text-center'>
                <img width='80px' height='80px' src={loyaOneLogo} />
              </div>
              <span className='app-text'>{t('customer_service')}</span>
            </div>

            <div className='app-btn lw-shadow' onClick={applicationStore.onClickLoyaWorld}>
              <div className='text-center'>
                <img width='80px' height='80px' src={loyaWorldLogo} />
              </div>
              <span className='app-text'>{t('business')}</span>
            </div>
          </div>

        </div>

      </div >


    </div >

  );
};

const ObserverApplication = observer(RenderApplication);
export const Application = () => { return (<Wrapper> <ObserverApplication /> </Wrapper >) };
