import React from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { stores } from '../../../store';
import { RouterHistoryStoreClass } from './routerHistoryStore';
import { logger } from '../../../helpers/logger';

export let routerHistoryStore: RouterHistoryStoreClass;

class Wrapper extends React.Component {

  componentDidMount() {
    routerHistoryStore?.init();
  }

  render() {
    return this.props.children;
  }
}

const Render = () => {

  const history = useHistory();
  if (history && history.push) {
    stores.history = history;
  } else {
    logger.error('Cannot get history!!!');
  }

  return (
    <>
    </>
  );
};

const ObserverLayout = observer(Render);
export const RouterHistory = () => { return (<Wrapper> <ObserverLayout /> </Wrapper >) };
