import { observable } from "mobx";
import { createContext } from "react";
import { localAppStorageHelper } from "../../../helpers/localAppStorageHelper";
import { url } from "inspector";
import { URI } from "../../../constant";
import { Account } from "../../../models/account";
import { appConfig } from "../../../appConfig";
import { authHelper } from "../../../helpers/authHelper";
import { Route, Redirect } from 'react-router-dom';
import { stores } from "../../../store";

export class ApplicationStoreClass {

  @observable history: any;
  @observable account: Account = {} as any;
  // @observable query = new URLSearchParams();

  constructor() {
  }

  init = async () => {
    this.account = await localAppStorageHelper.getAccount();
    // check current logged in data
    // authHelper.validateLoggedInData(this.query);
  }

  onClickSignOut = () => {
    localAppStorageHelper.clear();
    this.history.push(URI.LOGIN);
  }

  onClickLoyaOne = () => {
    authHelper.navigateToLoyaOne();
  }

  onClickLoyaWorld = () => {
    authHelper.navigateToLoyaWorld();
  }

  onClickAccountInfo = () => {
    stores.history.push({
      search: '',
      pathname: URI.ACCOUNT_INFO,
    });
  }

}

export const ApplicationStore = createContext(new ApplicationStoreClass());
