import { localAppStorageHelper, LocalStorageKeyEnum } from "./localAppStorageHelper";
import { appConfig } from "../appConfig";
import { logger } from "./logger";
import { authApi } from "../api/auth.api";
import { STATUS_CODE, LOYA_URL_KEY, LOYA_SERVICE, URI } from "../constant";
import { helper } from "./helper";
import { Account } from "../models/account";
import { stores } from "../store";
import { alertHelper } from "./toastHelper";
class AuthHelper {

    async navigateToLoyaOne() {
        const at = await localAppStorageHelper.getAccessToken();
        location.href = appConfig.config.loyaOneBizLink + `?at=${at}`;
    }

    async navigateToLoyaWorld() {
        const at = await localAppStorageHelper.getAccessToken();
        location.href = appConfig.config.loyaWorldBizLink + `?at=${at}`;
    }

    validateLoggedInData = async (query: URLSearchParams, ignoreGoToLogin = false, ignoreReturnToService = false) => {
        logger.debug('Validate logged data');
        let success = false;
        try {
            const res = await authApi.getAccountInfo();
            if (res.status_code == STATUS_CODE.SUCCESS) {
                success = true;
                const at = await localAppStorageHelper.getAccessToken();
                this.processLogin({
                    account: res.data,
                    at: at,
                    ignoreReturnToService: ignoreReturnToService
                }, query);
            } else if (res.status_code === STATUS_CODE.INVALID_STATUS
                || res.status_code === STATUS_CODE.ID_INVALID) {
                this.processLogout();
                // BE said
                // ID_INVALID => brand đó đã bị bay màu khỏi hệ thống
                // INVALID_STATUS => brand đã bị khóa
                alertHelper.warning('brand_was_locked_please_contact');
            }
        } catch (err) {
            helper.processCommonError(err);
        } finally {
            if (!success && !ignoreGoToLogin) {
                stores.history.push(URI.LOGIN);
            }
            if (!success && !ignoreGoToLogin) {
                this.processLogout();
            }
        }

    }

    processLogout = () => {
        localAppStorageHelper.clear();
        localAppStorageHelper.setLogoutBroadCast({ logout: true });
    }

    processLogin = (res: { at: string, account: Account, ignoreReturnToService?: boolean }, query: URLSearchParams) => {
        logger.debug('PROCESS LOGGED IN', res)
        if (res?.at || res?.account) {
            // save token
            localAppStorageHelper.setAccessToken(res?.at);
            // save user
            localAppStorageHelper.setAccount(res?.account);
            // save current tab active
            localAppStorageHelper.setLogoutBroadCast({ login: true });
        }
        if (!res.ignoreReturnToService) {
            const service = query?.get(LOYA_URL_KEY.SERVICE)?.toString()?.trim();

            const screen = query?.get(LOYA_URL_KEY.SCREEN_URI)?.toString()?.trim() || '';
            if (service == LOYA_SERVICE.LOYA_ONE) {
                location.href = appConfig.config.loyaOneBizLink + `?${LOYA_URL_KEY.AT}=${res.at}&${LOYA_URL_KEY.SCREEN_URI}=${screen}`;
            } else if (service == LOYA_SERVICE.LOYA_WORLD) {
                location.href = appConfig.config.loyaWorldBizLink + `?${LOYA_URL_KEY.AT}=${res.at}&${LOYA_URL_KEY.SCREEN_URI}=${screen}`;
            } else {
                stores.history?.push(URI.APPLICATION);
            }
        }
    }
}

export const authHelper = new AuthHelper();

