interface pushFunction {
    (uri: string): void;
    (data: { pathname: string, search: string }): void;
}
interface StoresInterface {
    history: {
        push: pushFunction,
    };
}
export const stores: StoresInterface = {

    history: { push: () => { } },

    // constructor() {

    // }

}


