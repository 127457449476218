import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import './forgotPassword.scss';
import logo from '../../../assets/imgs/logo.png';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { appConfig } from '../../../appConfig';
import { ForgotPasswordStoreClass, ForgotPasswordStore } from './forgotPasswordStore';
import { FooterComponent } from '../../../components/footer/footerComponent';

export let forgotPasswordStore: ForgotPasswordStoreClass;

class Wrapper extends React.Component {

  componentDidMount() {
    forgotPasswordStore?.init();
  }

  render() {
    return this.props.children;
  }
}

const Render = () => {

  const { t } = useTranslation();
  forgotPasswordStore = useContext(ForgotPasswordStore);
  forgotPasswordStore.query = new URLSearchParams(useLocation().search);

  return (
    <div className='login-container'>
      <div className='card w-100'>
        <div className='card-body'>
          <form onSubmit={forgotPasswordStore.onSubmitLogin}>
            <div className='text-center'>
              <img width='80px' height='80px' src={logo} />
            </div>
            <h1 className='text-center'>{t('forgot_password')}</h1>
            <span className='text-muted'>{t('input_for_reset_password')}</span>
            <div className='mb-2 mt-2'>
              <label className='input-label'>{t('brand_code')}</label>
              <div className='input-group'>
                <div className='input-group-prepend'   >
                  <span className="input-group-text"><i className="fa fa-home"></i></span>
                </div>
                <input type='text' name='brandCode' placeholder={t('brand_code')} className='form-control'
                  value={forgotPasswordStore.brandCode}
                  onChange={(e: any) => forgotPasswordStore.brandCodeChange(e.target.value)} />
              </div>
              {
                !forgotPasswordStore.brandCode && <span className='text-error' >
                  {t('required')}
                </span>
              }
            </div>

            <div className='mb-2'>
              <label className='input-label'>{t('email')}</label>
              <div className='input-group'>
                <div className='input-group-prepend'  >
                  <span className="input-group-text"><i className="fa fa-envelope"></i></span>
                </div>
                <input type='text' name='username' placeholder={t('email')} className='form-control'
                  value={forgotPasswordStore.username}
                  onChange={(e: any) => forgotPasswordStore.usernameChange(e.target.value)}
                />
              </div>
              {
                !forgotPasswordStore.username && <span className='text-error' >
                  {t('required')}
                </span>
              }
            </div>
            <div className='row'>
              <div className='col-12 text-center'>
                <button type='submit' disabled={
                  !forgotPasswordStore.brandCode?.trim()
                  || !forgotPasswordStore.username.trim()
                }
                  className='btn btn-primary px-4'>{t('confirm')}</button>
              </div>
            </div>

            <div className='text-left'>
              <button type='button' onClick={forgotPasswordStore.onClickGoBackToLogin} className='btn btn-link px-0'>
                &lt;{t('back_to_login')}
              </button>
            </div >

            {
              forgotPasswordStore.isSent &&
              <div className="alert alert-success">
                {t('request_forgot_password_sent')}
              </div>
            }

            <FooterComponent></FooterComponent>
          </form>
        </div >
      </div >


    </div >

  );
};

const ObserverLogin = observer(Render);
export const ForgotPassword = () => { return (<Wrapper> <ObserverLogin /> </Wrapper>) };
