export default {
    title: 'Xin chào',
    sign_to_your_account: 'Đăng nhập vào tài khoản của bạn',
    required: 'Bắt buộc',
    login: 'Đăng nhập',
    fogot_password: 'Quên mật khẩu?',
    brand_was_locked_please_contact: 'Thương hiệu đã bị khóa. Liên hệ Loya để biết thêm chi tiết',
    wrong_login_data: 'Sai thông tin đăng nhập',
    account_has_been_locked: 'Tài khoản đã bị khóa, liên hệ Quản lý để biết thêm chi tiết',
    customer_service: 'Chăm sóc khách hàng',
    business: 'Bán hàng',
    select_service_for_use: 'Chọn dịch vụ sử dụng',
    back: 'Trở về',
    back_to_login: 'Về đăng nhập',
    hi: 'Xin chào',
    sign_out: 'Đăng xuất',
    something_went_wrong: 'Lỗi không xác định. Vui lòng tải lại trang',
    input_for_reset_password: 'Nhập email và mã thương hiệu để khôi phục lại mật khẩu',
    forgot_password: 'Quên mật khẩu',
    brand_code: 'Mã thương hiệu',
    email: 'Email',
    password: 'Mật khẩu',
    confirm: 'Xác nhận',
    wrong_forgot_pass_message: 'Thông tin tài khoản hoặc mã thương hiệu không đúng',
    request_forgot_password_sent: "Yêu cầu đã được gửi đến email của bạn. Vui lòng kiếm tra hộp thư để tạo mật khẩu mới",
    retype_password_not_match: 'Mật khẩu chưa trùng khớp',
    retype_password: 'Nhập lại mật khẩu',
    account_is_not_exist: 'Tài khoản không tồn tại',
    password_was_set_before: 'Mật khẩu đã được tạo trước đó',
    create_password: 'Tạo mật khẩu',
    password_must_be_at_least_character: 'Mật khẩu tối thiểu 6 ký tự',
    profile: 'Thông tin tài khoản',
    phone: 'Điện thoại',
    change_password: 'Đổi mật khẩu',
    notify_setting: 'Cài đặt thông báo',
    notify_customer_service: 'Thông báo chăm sóc khách hàng',
    notify_order: 'Thông báo đơn hàng',
    notify_warehouse: 'Thông báo kho',
    dismiss: 'Hủy',
    save: 'Lưu',
    old_password: 'Mật khẩu cũ',
    new_password: 'Mật khẩu mới',
    confirm_password: 'Nhập lại mật khẩu',
    successfully_change_password: 'Thay đổi mật khẩu thành công',
    wrong_old_password: 'Mật khẩu cũ không đúng',
    confirm_password_not_match: 'Mật khẩu không trùng khớp',
    edited_success: 'Sửa thành công',
    login_session_expired: 'Phiên đăng nhập hết hạn',
    system_error: 'Hệ thống đang bảo trì'
}