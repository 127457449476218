import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import './footerComponent.scss';
import { useTranslation } from 'react-i18next';
import { FooterStore, FooterStoreClass } from './footerComponentStore';
import { useHistory, useLocation } from 'react-router-dom';
import { appConfig } from '../../appConfig';

let footerStore: FooterStoreClass;

class Wrapper extends React.Component {

  componentDidMount() {
    footerStore?.init();
  }

  render() {
    return this.props.children;
  }
}

const RenderFooter = () => {

  const { t } = useTranslation();
  footerStore = useContext(FooterStore);
  footerStore.history = useHistory();

  return (
    <div className="text-center py-2">
      <small className='text-secondary'>Copyright by Loya world. Version {appConfig.config.appVersion}</small>
    </div>
  );
};

const ObserverFooter = observer(RenderFooter);
export const FooterComponent = () => { return (<Wrapper> <ObserverFooter /> </Wrapper >) };
