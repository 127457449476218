import { observable } from "mobx";
import { createContext } from "react";
import { URI, EVENT_EMITER, STATUS_CODE, LOGIN_TYPE, LOYA_URL_KEY, } from "../../../constant";
import { authHelper } from "../../../helpers/authHelper";
import { stores } from "../../../store";
import { globalEvent } from "../../../helpers/globalEvents";
import { helper } from "../../../helpers/helper";
import { authApi } from "../../../api/auth.api";
import { ForgotPasswordRequestBody, ResetPasswordRequestBody } from "../../../models/auth.model";
import { alertHelper } from "../../../helpers/toastHelper";

export class ForgotPasswordStoreClass {

  @observable brandCode = '';
  @observable username = '';
  @observable service = '';
  @observable screenUri = '';
  @observable query = new URLSearchParams();
  @observable isSent = false;

  constructor() {

  }

  init = () => {
    this.isSent = false;
    authHelper.validateLoggedInData(this.query, true);
  if (this.query.get('brandCode')) {
      this.brandCode = this.query.get('brandCode') || '';
    }
    if (this.query.get('username')) {
      this.username = this.query.get('username') || '';
    }
    if (this.query.get(LOYA_URL_KEY.SERVICE)) {
      this.service = this.query.get(LOYA_URL_KEY.SERVICE) || '';
    }
    if (this.query.get(LOYA_URL_KEY.SCREEN_URI)) {
      this.screenUri = this.query.get(LOYA_URL_KEY.SCREEN_URI) || '';
    }
  }

  brandCodeChange = (text: string) => {
    this.brandCode = text;
  }

  usernameChange = (text: string) => {
    this.username = text;
  }

  onSubmitLogin = async (event: any) => {
    event.preventDefault();
    try {
      globalEvent.loadingAll.emit(EVENT_EMITER.LOADING_ALL, true);
      // const brandCode = helper.getUrlParams('brandCode');
      // const username = helper.getUrlParams('username');
      const model: ForgotPasswordRequestBody = {
        code: this.brandCode?.trim(),
        email: this.username?.trim(),
        type: LOGIN_TYPE.MERCHANT,
        service: this.service,
        screenUri: this.screenUri,
        bizApp: true,
      };
      const res = await authApi.forgotPassword(model);

      if (res.status_code === STATUS_CODE.SUCCESS) {
        this.isSent = true;
      } else if (res.status_code === STATUS_CODE.NOT_FOUND
        || res.status_code === STATUS_CODE.PASSWORD_INVALID
        || res.status_code === STATUS_CODE.ERROR_UNDEFINED
        || res.status_code === STATUS_CODE.WRONG_BRAND_CODE) {
        alertHelper.warning('wrong_forgot_pass_message');
      } else if (res.status_code === STATUS_CODE.INVALID_STATUS) {
        alertHelper.warning('brand_was_locked_please_contact');
      } else {
        throw res;
      }
    } catch (err) {
      helper.processCommonError(err);
    } finally {
      globalEvent.loadingAll.emit(EVENT_EMITER.LOADING_ALL, false);
    }
  }

  onClickGoBackToLogin = () => {
    stores.history.push({
      search: `?brandCode=&username=${this.username}&${LOYA_URL_KEY.SERVICE}=${this.service}&${LOYA_URL_KEY.SCREEN_URI}=${this.screenUri}`,
      pathname: URI.LOGIN,
    });
  }

}

export const ForgotPasswordStore = createContext(new ForgotPasswordStoreClass());
