import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import './login.scss';
import { LOYA_NAME, URI } from '../../../constant';
import logo from '../../../assets/imgs/logo.png';
import { useTranslation } from 'react-i18next';
import { LoginStore, LoginStoreClass } from './loginStore';
import { useHistory, useLocation } from 'react-router-dom';
import { appConfig } from '../../../appConfig';
import { stores } from '../../../store';
import { FooterComponent } from '../../../components/footer/footerComponent';

export let loginStore: LoginStoreClass;

class Wrapper extends React.Component {

  componentDidMount() {
    loginStore?.init();
  }

  render() {
    return this.props.children;
  }
}

const RenderLogin = () => {

  const { t } = useTranslation();
  loginStore = useContext(LoginStore);
  loginStore.query = new URLSearchParams(useLocation().search);

  return (
    <div className='login-container'>
      <div className='card w-100'>
        <div className='card-body'>
          <form onSubmit={loginStore.onSubmitLogin}>
            <div className='text-center'>
              <img width='80px' height='80px' src={logo} />
            </div>
            <h1 className='text-center'>{LOYA_NAME}</h1>
            <span className='text-muted'>{t('sign_to_your_account')}</span>
            <div className='mb-1 mt-2'>
              <label  className='input-label'>{t('brand_code')}</label>
              <div className='input-group'>
                <div className='input-group-prepend'   >
                  <span className="input-group-text"><i className="fa fa-home"></i></span>
                </div>
                <input type='text' name='brandCode' placeholder={t('brand_code')} className='form-control'
                  value={loginStore.brandCode}
                  onChange={(e: any) => loginStore.brandCodeChange(e.target.value)} />
              </div>
              {
                !loginStore.brandCode && <span className='text-error' >
                  {t('required')}
                </span>
              }
            </div>

            <div className='mb-1'>
            <label  className='input-label'>{t('email')}</label>
              <div className='input-group'>
                <div className='input-group-prepend'  >
                  <span className="input-group-text"><i className="fa fa-envelope"></i></span>
                </div>
                <input type='text' name='username' placeholder={t('email')} className='form-control'
                  value={loginStore.username}
                  onChange={(e: any) => loginStore.usernameChange(e.target.value)}
                />
              </div>
              {
                !loginStore.username && <span className='text-error' >
                  {t('required')}
                </span>
              }
            </div>
            <div className='mb-1'>
            <label  className='input-label'>{t('password')}</label>
              <div className='input-group'>
                <div className='input-group-prepend'  >
                  <span className="input-group-text"><i className="fa fa-lock"></i></span>
                </div>
                <input type='password' className='form-control' placeholder={t('password')} name='password'
                  autoComplete='current-password'
                  value={loginStore.password}
                  onChange={(e: any) => loginStore.passwordChange(e.target.value)}
                />
              </div>
              {
                !loginStore.password && <span className='text-error'>    {t('required')}  </span>
              }
            </div >
            <div className='row'>
              <div className='col-12 text-center'>
                <button type='submit' disabled={
                  !loginStore.brandCode?.trim()
                  || !loginStore.username.trim()
                  || !loginStore.password.trim()
                }
                  className='btn btn-primary px-4'>{t('login')}</button>
              </div>
              <div className='col-12 text-right'>
                <button onClick={loginStore.onClickForgotPassword} type='button' className='btn btn-link px-0'>
                  {t('fogot_password')}
                </button>
              </div >
            </div >

            <FooterComponent></FooterComponent>
          </form>
        </div >
      </div >


    </div >

  );
};

const ObserverLogin = observer(RenderLogin);
export const Login = () => { return (<Wrapper> <ObserverLogin /> </Wrapper >) };
