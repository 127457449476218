import { observable } from "mobx";
import { createContext } from "react";

export class FooterStoreClass {

  @observable history: any;

  constructor() {
  }

  init = async () => {

  }

}

export const FooterStore = createContext(new FooterStoreClass());
