import { createContext } from "react";
import { toast } from "react-toastify";
import { EVENT_EMITER, URI } from "./constant";
import { globalEvent } from "./helpers/globalEvents";
import { observable } from "mobx";
import { LocalStorageKeyEnum } from "./helpers/localAppStorageHelper";
import { logger } from "./helpers/logger";
import { stores } from "./store";

const DEFAULT_TOASTR_DURATION = 5000;
class AppStore {

    @observable t: any;
    @observable loading = false;

    constructor() {

        // loading
        globalEvent.loadingAll.addListener(EVENT_EMITER.LOADING_ALL, ((show: boolean) => {
            this.loading = show;
        }));

        // alert
        globalEvent.toastr.addListener(EVENT_EMITER.TOASTR, (obj: { type: string, text: string }) => {
            const textTranslated = this.t(obj?.text || '');
            switch (obj?.type) {
                case 'success':
                    this.success(textTranslated);
                    break;
                case 'error':
                    this.error(textTranslated);
                    break;
                case 'info':
                    this.info(textTranslated);
                    break;
                case 'warning':
                    this.warning(textTranslated);
                    break;
            }
        });

        this.initBroadCastLocaStorage();
    }

    initBroadCastLocaStorage = () => {
        const messageReceive = (ev: any) => {
            if (ev.key == LocalStorageKeyEnum.logoutBroadcast) {
                logger.debug('Received broadcast', ev);
                let data: any;
                try {
                    data = JSON.parse(ev.newValue);
                } catch{ }
                if (data) {
                    logger.debug("broadcast multitab data", data)
                    if (data.login) {
                        // localStorage.removeItem(LocalStorageKeyEnum.logoutBroadcast);
                        location.reload();
                    } else if (data.logout) {
                        stores.history?.push(URI.LOGIN);
                    }
                }

            }
        }

        window.addEventListener('storage', messageReceive);
    }


    success = (txt: string) => {
        toast.success(this.t(txt), {
            autoClose: DEFAULT_TOASTR_DURATION,
        });
    }
    info = (txt: string) => {
        toast.info(this.t(txt), {
            autoClose: DEFAULT_TOASTR_DURATION,
        });
    }
    warning = (txt: string) => {
        toast.warning(this.t(txt), {
            autoClose: DEFAULT_TOASTR_DURATION,
        });
    }
    error = (txt: string) => {
        toast.error(this.t(txt), {
            autoClose: DEFAULT_TOASTR_DURATION,
        });
    }



}

export default createContext(new AppStore());
