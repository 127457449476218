import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import logo from '../../../assets/imgs/logo.png';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { appConfig } from '../../../appConfig';
import { AccountInfoStore, AccountInfoStoreClass } from './accountInfoStore';
import './accountInfo.scss';
import userIcon from '../../../assets/imgs/user-icon-placeholder.png';
import Switch from "react-switch";
import Modal from 'react-modal';
import { MIN_PASSWORD_LENGTH } from '../../../constant';
import { FooterComponent } from '../../../components/footer/footerComponent';


export let accountInfoStore: AccountInfoStoreClass;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        height: 'auto',

    }
};
Modal.setAppElement('#root');

class Wrapper extends React.Component {

    componentDidMount() {
        accountInfoStore?.init();
    }

    render() {
        return this.props.children;
    }
}

const RenderAccountInfo = () => {

    const { t } = useTranslation();
    accountInfoStore = useContext(AccountInfoStore);
    accountInfoStore.query = new URLSearchParams(useLocation().search);

    return (
        <div className="animated fadeIn">
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header d-flex align-items-center justify-content-between py-1">
                            <div className="font-weight-bold">
                                {t('profile')}
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <div className="box-avatar-container">
                                        <div className="box-avatar-div-container">
                                            <img width='80px' height='80px' className="mr-3" src={accountInfoStore.account?.urlAvatar || userIcon} />
                                        </div>
                                        <div className="box-content">
                                            <h5>{t(accountInfoStore.account?.name)}</h5>
                                            <div>
                                                <span className="font-weight-bold mr-1">{t('email')}:</span>
                                                <span>
                                                    {accountInfoStore.account?.email}
                                                </span>
                                            </div>
                                            <div>
                                                <span className="font-weight-bold mr-1">{t('phone')}:</span>
                                                <span>
                                                    {accountInfoStore.account?.phone}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <br></br>
                            <div className="row">
                                <div className="col-12">
                                    <button className="btn btn-primary"
                                        onClick={accountInfoStore.onClickChangePassword}>{t('change_password')}</button>
                                </div>
                                <Modal
                                    isOpen={accountInfoStore.isOpenModal}
                                    onRequestClose={accountInfoStore.closeModal}
                                    style={customStyles}
                                >
                                    <div className="modal-container">
                                        <span className="del-modal" onClick={accountInfoStore.closeModal}><i className="fa fa-times"></i></span>
                                        <form onSubmit={accountInfoStore.onSubmitChangePassword}>
                                            <div className="modal-body">
                                                <div className="row justify-content-center">
                                                    <h4>{t('change_password')}</h4>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <label>{t('old_password')} </label>:
                                                        <input type="password" value={accountInfoStore.oldPassword} name="oldPassword" className="form-control"
                                                            onChange={(e: any) => accountInfoStore.oldPasswordChange(e.target.value)} />
                                                    </div>
                                                </div>
                                                {
                                                    !accountInfoStore.oldPassword && <span className='text-error' >
                                                        {t('required')}
                                                    </span>
                                                }

                                                <div className="row">
                                                    <div className="col-12">
                                                        <label >{t('new_password')} </label>:
                                                        <input type="password" value={accountInfoStore.newPassword} name="newPassword" className="form-control" minLength={MIN_PASSWORD_LENGTH}
                                                            onChange={(e: any) => accountInfoStore.newPasswordChange(e.target.value)} />
                                                    </div>
                                                </div>
                                                {
                                                    !accountInfoStore.newPassword && <span className='text-error' >
                                                        {t('required')}
                                                    </span>
                                                }
                                                {
                                                    (accountInfoStore.newPassword.length > 0
                                                        && accountInfoStore.newPassword.length < MIN_PASSWORD_LENGTH) && <span className='text-error' >
                                                        {t('password_must_be_at_least_character')}
                                                    </span>
                                                }

                                                <div className="row">
                                                    <div className="col-12">
                                                        <label>{t('confirm_password')}</label>:
                                                        <input type="password" value={accountInfoStore.retypePassword} name="retypePassword" className="form-control"
                                                            onChange={(e: any) => accountInfoStore.retypePasswordChange(e.target.value)} />
                                                    </div>
                                                </div>
                                                {
                                                    !accountInfoStore.retypePassword && <span className='text-error' >
                                                        {t('required')}
                                                    </span>
                                                }
                                                {
                                                    accountInfoStore.retypePassword && accountInfoStore.newPassword && accountInfoStore.retypePassword != accountInfoStore.newPassword && <span className='text-error' >
                                                        {t('confirm_password_not_match')}
                                                    </span>
                                                }


                                            </div>

                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-outline-primary" onClick={accountInfoStore.closeModal}>{t('dismiss')}</button>
                                                <button type='submit' className='btn btn-primary'
                                                    disabled={
                                                        !accountInfoStore.retypePassword?.trim()
                                                        || !accountInfoStore.newPassword?.trim()
                                                        || !accountInfoStore.oldPassword?.trim()
                                                        || accountInfoStore.retypePassword?.trim() != accountInfoStore.newPassword?.trim()
                                                    }
                                                >{t('save')}</button>
                                            </div>

                                        </form>
                                    </div>
                                </Modal>
                            </div>
                            <br></br>
                            <div className="row">
                                <div className="col-12">
                                    <h5>{t('notify_setting')}:</h5>
                                </div>
                            </div>
                            <div className="row align-content">
                                <div className="col-6">
                                    <span className="label-content"> {t('notify_customer_service')}:</span>
                                </div>
                                <div className="col-6">
                                    <span>
                                        <Switch checkedIcon={false} uncheckedIcon={true} onChange={accountInfoStore.handleChangeService} checked={accountInfoStore.serviceChecked} />
                                    </span>
                                </div>
                            </div>
                            <div className="row align-content">
                                <div className="col-6">
                                    <span className="label-content">{t('notify_order')}:</span>
                                </div>
                                <div>
                                    <span className="col-6">
                                        <Switch checkedIcon={false} uncheckedIcon={true} onChange={accountInfoStore.handleChangeOrder} checked={accountInfoStore.orderChecked} />
                                    </span>
                                </div>
                            </div>
                            <div className="row align-content">
                                <div className="col-6">
                                    <span className="label-content">{t('notify_warehouse')}:</span>
                                </div>
                                <div className="col-6">
                                    <span>
                                        <Switch checkedIcon={false} uncheckedIcon={true} onChange={accountInfoStore.handleChangeWarehouse} checked={accountInfoStore.warehouseChecked} />
                                    </span>
                                </div>
                            </div>

                        </div>
                        <div className='text-left ml-2'>
                            <button type='button' onClick={accountInfoStore.onClickGoBackToAuth} className='btn btn-link px-0'>
                                &lt;{t('back')}
                            </button>
                        </div >

                    </div>
                </div>
            </div>


        </div>

    );
};

const ObserverAccountInfo = observer(RenderAccountInfo);
export const AccountInfo = () => { return (<Wrapper> <ObserverAccountInfo /> </Wrapper>) };
