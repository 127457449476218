import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory
} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import AppStore from "./AppStore";
import { URI } from "./constant";
import { appConfig, config } from "./appConfig";
import { logger } from "./helpers/logger";
import loadingIcon from './assets/imgs/ajax-loader.gif';
import { observer } from "mobx-react-lite";
import { Login } from "./screens/auth/login/login";
import { Application } from "./screens/layout/application/application";
import { RouterHistory } from "./screens/auth/routerHistory/routerHistory";
import { ForgotPassword } from "./screens/auth/forgotPassword/forgotPassword";
import { NewPassword } from "./screens/auth/newPassword/newPassword";
import { AccountInfo } from "./screens/layout/account/accountInfo";
import { Layout } from "./screens/layout/layout";



if (appConfig.config.name == config.production.name
  && !(!process.env.NODE_ENV || process.env.NODE_ENV === 'development')) {
  if (window) {
    if (localStorage.getItem('dev') === 'true') {
      console.log('Dev mode is enable');
    } else {
      const emptyFunc = function () { };
      window.console.log = emptyFunc;
      window.console.warn = emptyFunc;
      window.console.info = emptyFunc;
      window.console.error = emptyFunc;
      logger.log = emptyFunc;
      logger.debug = emptyFunc;
      logger.warn = emptyFunc;
      logger.info = emptyFunc;
      logger.error = emptyFunc;
    }
  }
}

const App = () => {
  const { t, i18n } = useTranslation();
  // const changeLanguage = (lng: string) => {
  // i18n.changeLanguage('vi');
  // };
  const appStore = useContext(AppStore);
  appStore.t = t;

  return (
    <div>
      <div className="body">
        {
          appStore.loading &&
          <div className="loading-whole-page">
            <img src={loadingIcon} width="70" />
          </div>
        }
        <div className="container">

          <Router>
            {/* <nav>
              <ul>
                <li>
                  <Link to="/">Home  {t('title')}</Link>
                </li>
                <li>
                  <Link to="/application">Home  {t('title2')}</Link>
                </li>
                
              </ul>
            </nav> */}
            {/* <button onClick={() => changeLanguage('vi')}>vi</button>
            <button onClick={() => changeLanguage('en')}>en</button>  */}
            <RouterHistory></RouterHistory>
            <Switch>
              <Route path={URI.FORGOT_PASWORD}>
                <ForgotPassword />
              </Route>
              <Route path={URI.NEW_PASWORD}>
                <NewPassword />
              </Route>
              <Route exact path={URI.LOGIN}>
                <Login />
              </Route>
              <Layout />
              <Route path="*" component={Login} />
            </Switch>
          </Router>
        </div>
      </div>
      <ToastContainer />

    </div>
  );
};

export default observer(App);
