import { observable } from "mobx";
import { createContext } from "react";
import { Account } from "../../models/account";
import { authHelper } from "../../helpers/authHelper";
import { URI } from "../../constant";
import { stores } from "../../store";

export class LayoutStoreClass {

  @observable history: any;
  @observable account: Account = {} as any;
  @observable query = new URLSearchParams();
  location = {} as any;

  constructor() {
  }

  init = async () => {
    console.log(location.pathname);
    if (location.pathname == URI.LAYOUT) {
      stores.history.push({
        search: ``,
        pathname: URI.LOGIN,
      });
    } else {
      // check current logged in data
      authHelper.validateLoggedInData(this.query, false, true);
    }
  }

  // onClickSignOut = () => {
  //   localAppStorageHelper.clear();
  //   this.history.push(URI.LOGIN);
  // }

  // onClickLoyaOne = () => {
  //   authHelper.navigateToLoyaOne();
  // }

  // onClickLoyaWorld = () => {
  //   authHelper.navigateToLoyaWorld();
  // }

  // onClickAccountInfo = () => {
  //   stores.history.push({
  //     search: '',
  //     pathname: URI.ACCOUNT_INFO,
  //   });
  // }

}

export const LayoutStore = createContext(new LayoutStoreClass());
