import { observable } from "mobx";
import { createContext } from "react";
import { URI, EVENT_EMITER, STATUS_CODE, LOGIN_TYPE, LOYA_URL_KEY, LOYA_SERVICE, } from "../../../constant";
import { authHelper } from "../../../helpers/authHelper";
import { stores } from "../../../store";
import { globalEvent } from "../../../helpers/globalEvents";
import { helper } from "../../../helpers/helper";
import { authApi } from "../../../api/auth.api";
import { ForgotPasswordRequestBody } from "../../../models/auth.model";
import { alertHelper } from "../../../helpers/toastHelper";
const md5 = require('md5');


export class NewPasswordStoreClass {

  @observable password = '';
  @observable retypePassword = '';
  @observable username = '';
  @observable brandCode = '';
  @observable authToken = '';
  @observable id = '';
  @observable service = '';
  @observable screenUri = '';
  @observable query = new URLSearchParams();
  @observable isSent = false;

  constructor() {

  }

  init = () => {
    authHelper.validateLoggedInData(this.query, true);
    if (this.query.get('email')) {
      this.username = this.query.get('email') || '';
    }
    if (this.query.get('id')) {
      this.id = this.query.get('id') || '';
    }
    if (this.query.get('authToken')) {
      this.authToken = this.query.get('authToken') || '';
    }
    if (this.query.get('code')) {
      this.brandCode = this.query.get('code') || '';
    }
    if (this.query.get(LOYA_URL_KEY.SERVICE)) {
      this.service = this.query.get(LOYA_URL_KEY.SERVICE) || '';
    }
    if (this.query.get(LOYA_URL_KEY.SCREEN_URI)) {
      this.screenUri = this.query.get(LOYA_URL_KEY.SCREEN_URI) || '';
    }
  }

  passwordChange = (text: string) => {
    this.password = text;
  }

  retypePasswordChange = (text: string) => {
    this.retypePassword = text;
  }

  onSubmitLogin = async (event: any) => {
    event.preventDefault();
    try {
      globalEvent.loadingAll.emit(EVENT_EMITER.LOADING_ALL, true);
      // const brandCode = helper.getUrlParams('brandCode');
      // const username = helper.getUrlParams('username');
      const model: any = {
        id: this.id,
        authToken: this.authToken,
        password: md5(this.password),
      };
      const res = await authApi.newPassword(model);
      if (res.status_code === STATUS_CODE.SUCCESS) {
        authHelper.processLogin({
          account: res.data.account,
          at: res.data.authorization,
        }, this.query);
      } else if (res.status_code === STATUS_CODE.NOT_FOUND) {
        alertHelper.warning('account_is_not_exist');
      } else if (res.status_code === STATUS_CODE.DATA_IS_INVALID) {
        alertHelper.warning('password_was_set_before');
      } else if (res.status_code === STATUS_CODE.INVALID_STATUS) {
        alertHelper.warning('brand_was_locked_please_contact');
      } else {
        throw res;
      }
    } catch (err) {
      helper.processCommonError(err);
    } finally {
      globalEvent.loadingAll.emit(EVENT_EMITER.LOADING_ALL, false);
    }
  }

  onClickGoBackToLogin = () => {
    stores.history.push({
      search: `?brandCode=&username=${this.username}&${LOYA_URL_KEY.SERVICE}=${this.service}&${LOYA_URL_KEY.SCREEN_URI}=${this.screenUri}`,
      pathname: URI.LOGIN,
    });
  }

}

export const NewPasswordStore = createContext(new NewPasswordStoreClass());
