import { EVENT_EMITER } from "../constant";
import { globalEvent } from "./globalEvents";

class ToastHelper {

    constructor() { }

    success = (txt: string) => {
        globalEvent.toastr.emit(EVENT_EMITER.TOASTR, { type: 'success', text: txt });
    }
    warning = (txt: string) => {
        globalEvent.toastr.emit(EVENT_EMITER.TOASTR, { type: 'warning', text: txt });
    }
    error = (txt: string) => {
        globalEvent.toastr.emit(EVENT_EMITER.TOASTR, { type: 'error', text: txt });
    }
    info = (txt: string) => {
        globalEvent.toastr.emit(EVENT_EMITER.TOASTR, { type: 'info', text: txt });
    }
}

export const alertHelper = new ToastHelper();

