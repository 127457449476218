import { alertHelper } from "./toastHelper";
import { logger } from "./logger";

class Helper {

    processCommonError = (res: any) => {
        logger.error('Process common error', res);
        // alertHelper.error('something_went_wrong');
    }

    getUrlParams = (key: string): string => {
        var url = new URL(location.href);
        var c = url.searchParams.get(key);
        return c?.toString() || '';
    }

    convertToQueriesParams = (paramsIn: Object) => {
        const params: any = Object.assign({}, paramsIn);
        let paramUri = '';
        if (params) {
            paramUri = '?';
            const arr = Object.keys(params);
            arr.forEach((item: any) => {
                if (item === 'keyword' || item === 'phone') {
                    let data = encodeURIComponent(params[item]);
                    if (data.charAt(0) === '0') {
                        data = data.substring(1, data.length);
                    }
                    paramUri = paramUri + item + '=' + data + '&';
                } else {
                    if (Array.isArray(params[item])) {
                        params[item].forEach((data: string) => {
                            paramUri = paramUri + item + '=' + encodeURIComponent(data) + '&';
                        });
                    } else {
                        paramUri = paramUri + item + '=' + encodeURIComponent(params[item]) + '&';
                    }
                }
            });
        }
        return paramUri;
    }
}

export const helper = new Helper();

