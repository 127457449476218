export default {
    title: 'Hello',
    sign_to_your_account: 'Sign in to your account',
    required: 'Required',
    login: 'Login',
    fogot_password: 'Fogot password?',
    brand_was_locked_please_contact: 'The brand has been locked. Please contact Loya for more details',
    wrong_login_data: 'Wrong login data',
    account_has_been_locked: 'The account has been locked, contact manager for more details',
    customer_service: 'Customer service',
    business: 'Business',
    select_service_for_use: 'Select service for use',
    back: 'Back',
    back_to_login: 'Back to login',
    hi: 'Hi',
    sign_out: 'Sign out',
    something_went_wrong: 'Something went wrong',
    input_for_reset_password: 'Input email and brand code to reset password',
    forgot_password: 'Forgot password',
    brand_code: 'Brand code',
    email: 'Email',
    password: 'Password',
    confirm: 'Confirm',
    wrong_forgot_pass_message: 'Account or brand code invalid',
    request_forgot_password_sent: "Check your mailbox to reset your password",
    retype_password_not_match: 'The password was not match',
    retype_password: 'Re-type password',
    account_is_not_exist: 'Account is not exist',
    password_was_set_before: 'This account was set password before',
    create_password: 'Create password',
    password_must_be_at_least_character: 'Password must be at least 6 characters',
    profile: 'Profile',
    phone: 'Phone',
    change_password: 'Change password',
    notify_setting: 'Notification setting',
    notify_customer_service: 'Notification customer service',
    notify_order: 'Notification order',
    notify_warehouse: 'Notification warehouse',
    old_password: 'Old password',
    new_password: 'New password',
    confirm_password: 'Confirm password',
    save: 'Save',
    dismiss: 'Dismiss',
    successfully_change_password: 'Successfully changed password',
    wrong_old_password: 'The Old password is not correct',
    confirm_password_not_match: 'Confirm password not match with new password',
    edited_success: 'Successfully edited',
    login_session_expired: 'Session expired',
    system_error: 'System error'
}